/* Global Styles */
.mode-dark {
    background-color: #343a40 !important;
}

.mode-light {
    background-color: #fff !important;
}

.mode-light .rs-input {
    background-color: white;
    color: black;
}



.check-cell {
    width: 50px;
}

.collapse-area {
    display: flex;
    flex-direction: column;
    gap: 15px;

}

.not-column {
    display: none;
}


.head-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
}

.collapse-area {
    cursor: pointer;
}

.head-action-area {
    margin-right: 25px;
}

.with-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.checkbox-dropdown-label {
    padding: 8px 20px 1px 10px !important;
}

.main-collapse-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 15px;
}

.collapse-menu {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 20px;
}

.collapse-label {
    font-size: 15px;
    color: var(--rs-text-secondary);
}

.collapse-checker {
    width: 16px;
    height: 16px;
}

.collapse-main-label {
    font-size: 20px;
}

.main-collapse-wrapper {
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.collapse-button {
    width: 100%;
}


.custom-cell-action>.rs-table-cell-content {
    gap: 6px;
}

.auto-complete-clear {
    cursor: pointer;
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
}

.multi-menu-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.image-bordered-cover {
    border: 1px solid black;
    padding: 25px;
}

.g-10 {
    gap: 10px;
}

.cpointer {
    cursor: pointer;
}

.file-table-item {
    margin-top: 10px;
    width: 400px;
    height: 200px;
    cursor: pointer;
}



.image-prop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.file-action-wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.image-uploader-info-container {
    justify-content: center;
    margin-bottom: 10px;
    flex-direction: column;
    gap: 10px;
}

.image-resolution-button {
    margin-bottom: 60px;
    justify-content: center;
}

.image-uploader-text-container {
    justify-content: center;
    margin-bottom: 5px;
}

.resolution-definitions {
    flex-direction: column;
}

.uploader-image-viewer {
    max-width: 280px;
    padding-bottom: 25px;
    max-height: 175px;
    height: 150px;
    cursor: pointer;
}

.uploader-button {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.field-holder {
    gap: 25px;
}

.image-table-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hidden {
    display: none;
}

.image-viewer-empty {
    min-height: 200px;
    border-style: dotted;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-input-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-inner-section {
    margin-left: 25px;
}

.d-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-table-item-wrapper span a {
    color: #fff
}

.image-table-item-wrapper span a:hover {
    text-decoration: none;
    color: #fff
}

.under-const-image {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
}

.under-cons {
    height: 100%;
}

.brand-customized-link {

    display: flex;
    justify-content: center;
}

.login-customized {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.login-customized .login-box {
    margin-left: 15%;
}

.dropdown-menu {

    right: unset !important;
}

.style-settings-wrapper {
    justify-content: center;
    text-align: center !important;

}


.modal-form-control {
    margin-top: 10px;
    gap: 5px;
}


/* SAN Styles */
.san-form-dropdown>button {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-start !important;
    background-color: var(--rs-input-bg);
    color: var(--rs-gray-200);
}

.san-table-dark {
    border: 1px solid var(--rs-border-secondary);
}

.san-table-cell {
    border: 1px solid var(--rs-border-secondary) !important;
    text-align: center !important;
}

.san-table-header-text {
    color: var(--rs-text-secondary);
    font-size: 12px;
    text-align: center !important;
}

.san-table-collapse-area {
    padding: 10px;
    border: 1px solid var(--rs-border-secondary) !important;
    background-color: dimgray;
}

.san-table-row {
    display: flex;
    width: auto;
}

.san-collapse-picker-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.san-file-table {
    gap: 50px;
    margin: 20px;
}

.san-file-table-image {
    width: auto;
    height: auto;
    object-fit: cover;
}

.san-uploader-div {
    height: 150px;
    border: 1px dotted;
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.san-flexible {
    display: flex;
    justify-content: center;
    align-items: center;
}

.san-dropzone {
    width: 100%;
    height: 150px;
    border-style: dotted;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.san-dropzone-label {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.san-dropzone-wrapper img {
    object-fit: contain;
    height: 145px;
    width: 100%;
}

.san-dropzone-wrapper aside {
    position: relative;
    gap: 10px;
    display: flex;
    margin-top: 5px;
}

.brand-link {
    display: flex;
    justify-content: center;
}

.dropzone-title {
    text-align: start;
    margin-bottom: 5px;
}

.san-dropzone-remove,
.san-dropzone-upload {
    position: absolute;
    bottom: 0;
    right: 0;
}

.san-dropzone-remove button,
.san-dropzone-upload button {
    border-radius: 0 !important;
}

.san-menu-label {
    max-width: 160px;
    display: block !important;
}

.san-dd {
    margin-top: 10px;
    position: absolute;
    background: white;
    border: 1px solid black;
    list-style: none;
    position: "absolute"
}

.san-lk {
    display: flex;
    cursor: pointer;
    align-items: center;
    cursor: 'pointer'
}

/* RSuite Style Overrides */
.rs-form-control-wrapper {
    width: auto;
}

.rs-modal-body>.rs-modal-body-close {
    display: none;
}

.rs-modal-title {
    line-height: 1.31111111 !important;
}

.rs-avatar-xxl {
    width: 15vw;
    height: 10vh;
}

.rs-avatar-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}


.rs-modal-content {
    min-height: 300px;
    height: max-content;
    margin-bottom: 30px;
}


.rs-modal-body .justify-content-end.save-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.rs-modal-wrapper::-webkit-scrollbar {
    width: 10px;
}

.rs-modal-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(141, 141, 141, 0.3);
    border-radius: 10px;
}

.rs-modal-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: rgba(12, 12, 12, 0.5);
}

.rs-drawer-body::-webkit-scrollbar {
    width: 10px;
}

.rs-drawer-body::-webkit-scrollbar-thumb {
    background-color: rgba(141, 141, 141, 0.3);
    border-radius: 10px;
}

.rs-drawer-body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(12, 12, 12, 0.5);
}

.rs-drawer-body .justify-content-end.save-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

body.rs-theme-dark .rs-dropdown-toggle {
    color: #898989;
    border: 1px solid var(--rs-border-primary) !important;
    width: auto;
}

body.rs-theme-light .rs-dropdown-toggle {
    color: #898989;
    border: 1px solid var(--rs-border-primary) !important;
    width: auto;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999 !important;
    cursor: pointer;
}

.spinner {
    height: 100%;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
}


.pagination {
    justify-content: center;
    margin: 30px;
}

.pagination .pagination-area,
.pagination-info {
    padding: 10px;
    border: 0.5px solid grey;
    display: flex;
    gap: 10px;
    border-radius: 8px;
}

.san-filter {
    margin: 20px;
}

section.content {
    margin-right: 12px;
}

html,
body {
    overflow: hidden;
}

html,
#root {
    overflow-y: visible;
}

.gap-5 {
    gap: 5px;
}

.rs-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px 0;
    margin: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.rs-dropdown-menu::-webkit-scrollbar {
    width: 6px;
}

.rs-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.rs-dropdown-menu::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}


.form-control-wrap {
    display: flex;
}

.san-form-control-date {
    color: #898989
}

/* .san-form-control-date:hover {
    border-color: var(--rs-input-focus-border);
}

.san-form-control-date:focus-visible {
    outline: none !important;
}

.san-form-control-date:focus {
    outline: 3px solid var(--rs-color-focus-ring);
    border-color: var(--rs-input-focus-border);
} */

.san-form-control-date {
    width: 300px;
    display: inline-block;
    /* color: var(--rs-gray-200); */
    background-color: var(--rs-input-bg);
    background-image: none;
    border: 1px solid var(--rs-border-primary);
    transition: border-color ease-in-out 0.15s;
    border-radius: 6px;
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
}

.rs-input {
    color: #898989 !important;
    width: 100% !important;
}

.san-checkbox-picker {
    max-width: none !important;
}

.rs-checkbox-checker {
    padding-left: 0;
}

.rs-dropdown {
    width: 100% !important;
}

.rs-picker-input-group {
    width: 100% !important;
}

.password {
    position: relative;
}

.eye-icon {
    position: absolute;
    top: 50%;
    left: 90%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.fas.fa-eye-slash,
.fas.fa-eye {
    font-family: "Font Awesome 5 Free" !important;
}

.login-user {
    width: 100% !important;
}

.rs-form-fixed-width {
    width: 100% !important;
}

.form-row {
    margin-top: 10px;
    justify-content: center;
}

.form-button-row {
    margin-top: 25px;
    justify-content: flex-end;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.nav-sidebar {
    gap: 15px;
}

.sidebar-collapse .menu-right-container {
    display: none;
}

.sidebar-collapse .menu-left-container i {
    font-size: 1.5em;
}

.sidebar-collapse .sidebar nav {
    justify-content: center;
    display: flex;
}

.main-sidebar:hover .menu-left-container i {
    font-size: 1em;
}

.main-sidebar:hover .menu-right-container {
    display: block;
}

.nav-item {
    width: 100% !important;
}

.rs-sidenav {
    margin-top: 10px;
}

.rs-sidenav-body ul.rs-dropdown-menu {
    height: 100% !important;
    max-height: 100% !important;
}

.rs-sidenav-item-icon {
    position: absolute;
    left: 1em;
}

.rs-sidenav-item {
    display: flex;
    align-items: center;
}

.sidebar {
    scrollbar-color: unset;
}

.sidebar>nav {
    overflow-y: auto !important;
    max-height: 650px !important;
    overflow-x: hidden !important;
}

.sidebar>nav::-webkit-scrollbar {
    width: 6px;
}

.sidebar>nav::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.sidebar>nav::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.sidebar>nav .justify-content-end.save-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.text-area-input {
    min-width: 320px;
}

.rs-dropdown-item-toggle {
    white-space: normal;
}

.rs-checkbox-checker label {
    display: flex;
    gap: 5px
}

.rs-checkbox-label {
    display: flex;
}

.rs-form-help-text-tooltip {
    margin-top: 0;
}

.smtp-settings-wrapper {
    margin-top: 10px;
}

.rs-modal-body .rs-dropdown .rs-dropdown-menu {
    position: relative;
}

.rs-modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
}

.rs-modal-body::-webkit-scrollbar {
    width: 6px;
}

.rs-modal-body::-webkit-scrollbar-thumb {
    background-color: rgba(141, 141, 141, 0.3);
    border-radius: 10px;
}

.rs-modal-body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(12, 12, 12, 0.5);
}

.footer-area {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    overflow: hidden;
}

.footer-area .main-footer {
    margin: 0 !important;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fff8f836;
    color: #ffffff !important;
    border: 0;
}

.main-footer a {
    color: inherit
}

.main-footer {
    display: flex;
    justify-content: end;
}