@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css');

@import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
@import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import 'admin-lte/dist/css/adminlte.min.css';

@import 'react-toastify/dist/ReactToastify.min.css';

#root {
  width: 100vw;
  height: 100vh;
}

.main-header .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

/* @font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_opsz\,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter_18pt-ExtraLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter_18pt-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}